var mainMenu = document.getElementById('mainMenu');
var toggleBtn = document.getElementById('toggleBtn');
var body = document.body;
var nav = document.getElementById('nav');
var closeMenu = document.querySelector('.close-menu');
var nav_overlay = document.querySelector('.nav-overlay');
var overflow_scroll = document.querySelector('.overflow-scroll');

const images = document.querySelectorAll(".img-responsive");
image_reload();

window.addEventListener('resize', function(event) {
    image_reload();
}, true);

function image_reload() {
    if(window.innerWidth <= 768) {        
        images.forEach(function (image) {
            image.style.backgroundImage = "url(" + image.getAttribute('data-src-mobile') + ")"
        });
    } else {
        images.forEach(function (image) {
            image.style.backgroundImage = "url(" + image.getAttribute('data-src-desktop') + ")"

        });
    }
}

if(toggleBtn) {
    toggleBtn.onclick = function() {
        mainMenu.classList.toggle("js-active");
        toggleBtn.classList.toggle("js-show");
        nav.classList.toggle("js-nav-isactive");
        overflow_scroll.classList.toggle("overflow-scroll-is-active");
    };

    nav_overlay.onclick = function() {
        mainMenu.classList.toggle("js-active");
        toggleBtn.classList.toggle("js-show");
        nav.classList.toggle("js-nav-isactive");
        overflow_scroll.classList.toggle("overflow-scroll-is-active");
    };

    closeMenu.onclick = function() {
        mainMenu.classList.remove("js-active");
        toggleBtn.classList.remove("js-show");
        nav.classList.remove("js-nav-isactive");
        overflow_scroll.classList.remove("overflow-scroll-is-active");
    };
}

const menu_links = document.querySelectorAll('.menu-item-has-children > a');
 function clickHandler(e) {
    e.preventDefault()
}

for (const menu_link of menu_links) {
    menu_link.addEventListener("click", clickHandler);
}

const main_banner_sliders = document.querySelectorAll(".main-banner-slider");

if(main_banner_sliders) {
    main_banner_sliders.forEach(main_banner_slider => new Splide( main_banner_slider, {       
        perPage: 1,
        rewind : true,
        arrows: true,
        autoplay: true,
        pagination: false,
        breakpoints: {
            '640': {
                perPage: 1,
                gap    : '1rem',
            },
            '480': {
                perPage: 1,
                gap    : '1rem',
            },
        }
    } ).mount());
};

const carousel = document.querySelectorAll(".carousel-items");

if(carousel) {
    carousel.forEach(carousel => new Splide( carousel, {       
        perPage: 4,
        gap: '6.5rem',
        rewind : true,
        arrows: true,
        autoplay: true,
        pagination: false,
        breakpoints: {  
            
            '1200': {
                perPage: 4,
                gap: '5rem',
             },

            '820': {
                perPage: 3,
                gap: '4rem',
             },
                     
            '767': {
                perPage: 2,
                gap: '3rem',
            },
            '600': {
                perPage: 1,
                gap: '0rem',
            },
        }
    } ).mount());
};

const clientSlider = document.querySelectorAll(".client-items-wrapper");

if(clientSlider) {
    clientSlider.forEach(clientSlider => new Splide( clientSlider, {       
        perPage: 4,
        gap: '5rem',
        rewind : true,
        arrows: false,
        autoplay: true,
        pagination: false,
        type   : 'loop',
        padding: { left: 10, right: 20 },
        autoWidth: true,
        focus    : 0,
        omitEnd  : true,
        breakpoints: {  
            
            '1200': {
                perPage: 4,
                gap: '3rem',
             },

            '820': {
                perPage: 3,
                gap: '2rem',
             },
                     
            '767': {
                perPage: 2,
                gap: '2rem',
            },
            '600': {
                perPage: 1,
                gap: '1rem',
            },
        }
    } ).mount());
};

const clientSlider_2 = document.querySelectorAll(".client-items-wrapper-2");

if(clientSlider_2) {
    clientSlider_2.forEach(clientSlider_2 => new Splide( clientSlider_2, {       
        perPage: 4,
        gap: '5rem',
        rewind : true,
        arrows: false,
        autoplay: true,
        pagination: false,
        type   : 'loop',
        padding: { left: 10, right: 20 },
        autoWidth: true,
        focus    : 0,
        omitEnd  : true,
        direction: "rtl",
        breakpoints: {  
            
            '1200': {
                perPage: 4,
                gap: '3rem',
             },

            '820': {
                perPage: 3,
                gap: '2rem',
             },
                     
            '767': {
                perPage: 2,
                gap: '2rem',
            },
            '600': {
                perPage: 1,
                gap: '1rem',
            },
        }
    } ).mount());
};

const image_wrapper_splides = document.querySelectorAll(".image-wrapper-splide");

if(image_wrapper_splides) {
    image_wrapper_splides.forEach(image_wrapper_splide => new Splide( image_wrapper_splide, {       
        perPage: 1,
        rewind : true,
        arrows: false,
        autoplay: true,
        pagination: true,
    } ).mount());
};

const columns_slider_1 = document.querySelector(".columns-slider-1");
const columns_slider_2 = document.querySelector(".columns-slider-2");
const columns_slider_3 = document.querySelector(".columns-slider-3");
const columns_slider_4 = document.querySelector(".columns-slider-4");
const columns_slider_5 = document.querySelector(".columns-slider-5");

if(columns_slider_5) {
    new Splide( '.columns-slider-5', {
        perPage: 5,
        gap    : '5rem',
        rewind : true,
        arrows: false,
        autoplay: true,
        pagination: false,
        breakpoints: {
            '1480': {
                perPage: 4,
                arrows: true,
                gap    : '2.5rem',
            },
            '1024': {
                perPage: 3,
                arrows: true,
                gap    : '2rem',
            },
            '768': {
                perPage: 2,
                arrows: true,
                gap    : '1.5rem',
            },

            '480': {
                perPage: 1,
                arrows: true,
                gap    : '0rem',
            },
        }
    } ).mount();

};

if(columns_slider_4) {
    new Splide( '.columns-slider-4', {
        perPage: 4,
        gap    : '3rem',
        rewind : true,
        arrows: true,
        autoplay: false,
        pagination: false,
        breakpoints: {
            '1024': {
                perPage: 3,
                arrows: true,
                gap    : '2rem',
            },
            '768': {
                perPage: 2,
                arrows: true,
                gap    : '1.5rem',
            },

            '480': {
                perPage: 1,
                arrows: true,
                gap    : '0rem',
            },
        }
    } ).mount();
};

if(columns_slider_3) {
    new Splide( '.columns-slider-3', {
        perPage: 3,
        gap    : '4rem',
        rewind : true,
        arrows: false,
        autoplay: true,
        pagination: false,
        breakpoints: {
            '768': {
                perPage: 2,
                arrows: true,
                gap    : '1.5rem',
            },

            '480': {
                perPage: 1,
                arrows: true,
                gap    : '0rem',
            },
        }
    } ).mount();
};

if(columns_slider_2) {
    new Splide( '.columns-slider-2', {
        perPage: 2,
        gap    : '5rem',
        rewind : true,
        arrows: false,
        autoplay: true,
        pagination: false,
        breakpoints: {

            '480': {
                perPage: 1,
                arrows: true,
                gap    : '0rem',
            },
        }
    } ).mount();
};

// ANIMATED IMAGES
window.addEventListener("load", function() {
    const AnimateImgSections = document.querySelectorAll(".main-banner-content-flip-image,.main-banner-content-header")
    const options = {
        rootMargin: '100%',
        threshold: [0.25, 0, 0.25, 0]
    }
    
    let observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.intersectionRatio > 0) {
                entry.target.classList.add('has-animation-image')
            } 
        })
    }, options)
    
    AnimateImgSections.forEach(AnimateImgSection => { 
        observer.observe(AnimateImgSection)
    })
})

window.addEventListener("load", function() {
    const AnimateSingleImages = document.querySelectorAll(".has-bg-animation")
    
    const options = {
        rootMargin: '-200px 0px',
        threshold: [0.25, 0, 0.25, 0]
    }
    
    let observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.intersectionRatio > 0) {
                entry.target.classList.add('kenburns-top')
            }
        })
    }, options)
    
    AnimateSingleImages.forEach(AnimateSingleImage => { 
        observer.observe(AnimateSingleImage)
    })
});

window.addEventListener("scroll", function() {
    var header = document.getElementById("js-sticky-header");
    header.offsetBottom = header.offsetTop + header.offsetHeight
    var topPos = header.offsetBottom;

    if (window.pageYOffset > topPos) {
        header.classList.add("fixed");
    } else {
        header.classList.remove("fixed");
    }
});

window.addEventListener("load", function() {
    const scrollLinks = document.querySelectorAll('.scrollToSection');

    scrollLinks.forEach(link => {
        link.addEventListener('click', function(event) {
            event.preventDefault();
            const targetId = this.getAttribute('data-target');
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                const offset = window.innerWidth > 1024 ? 50 : 0;
                const targetPosition = targetElement.offsetTop - offset;

                window.scrollTo({
                    top: targetPosition,
                    behavior: 'smooth'
                });
            }
        });
    });
});
